import { Create, useForm } from '@refinedev/antd'
import {
  useCreate,
  useList,
  type IResourceComponentsProps,
} from '@refinedev/core'
import { Card, Col, Form, Input, InputNumber, Row, Select, Tag } from 'antd'

import type { ILocation, IOrganization } from '../../interfaces'

import { useState } from 'react'
import { Map } from './map'
import TimezoneInput from './timezone-input'

export const LocationsCreate: React.FC<IResourceComponentsProps> = () => {
  const { form, formProps, queryResult, saveButtonProps } = useForm<ILocation>()
  const [tagInput, setTagInput] = useState<string>('')
  const [tags, setTags] = useState<string[]>([])
  const { mutate } = useCreate<ILocation>({})

  const { data: organizations } = useList<IOrganization>({
    resource: 'organizations',
  })

  const removeItemFromTags = (item: string) => {
    setTags(tags.filter((tag) => tag !== item))
  }

  const addItemToTags = (item: string) => {
    setTags([...tags, item])
  }

  const handleSubmit = (values: any) => {
    if (
      values?.reservationReleaseTimeLocal === '' ||
      values?.reservationReleaseTimeLocal === null
    ) {
      delete values.reservationReleaseTimeLocal
    }
    const sanitizedValues = {
      ...values,
      tags,
    }
    mutate(
      {
        invalidates: ['all'],
        resource: 'locations',
        values: sanitizedValues,
      },
      {
        onSuccess: () => {
          form.resetFields()
          setTags([])
        },
      }
    )
  }

  return (
    <Create
      isLoading={queryResult?.isFetching}
      saveButtonProps={saveButtonProps}
    >
      <Form
        {...formProps}
        initialValues={{
          isActive: true,
        }}
        layout="vertical"
        style={{ marginTop: 30 }}
        onFinish={handleSubmit}
      >
        <Row gutter={20}>
          <Col lg={8} xs={24} />
          <Col lg={16} xs={24}>
            <Row gutter={10}>
              <Col lg={12} xs={24}>
                <Form.Item
                  label="Organization"
                  name="organizationId"
                  rules={[{ message: 'Please select organization' }]}
                >
                  <Select>
                    <Select.Option value={undefined}>
                      No organization
                    </Select.Option>
                    {organizations?.data.map((organization) => (
                      <Select.Option value={organization.id}>
                        {organization.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Name"
                  name="name"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Description"
                  name="description"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input.TextArea autoSize />
                </Form.Item>
                <TimezoneInput form={form} />
                <Form.Item
                  label="Access info"
                  name="accessInfo"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input.TextArea autoSize />
                </Form.Item>
                <Form.Item
                  label="Getting there info"
                  name="gettingThereInfo"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input.TextArea autoSize />
                </Form.Item>
                <Form.Item
                  label="Default reservation window (in days)"
                  name="defaultReservationWindow"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <InputNumber min={0} />
                </Form.Item>
                <Form.Item
                  label="Reservation release time (HH:mm:ss)"
                  name="reservationReleaseTimeLocal"
                  rules={[
                    {
                      required: false,
                      message:
                        'Invalid input. Must use 24-hour HH:MM:SS format (e.g. 13:30:00 for 1:30 PM)',
                      pattern: /^\d{2}:\d{2}:\d{2}$/,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item label="Hours of Operation" name="hoursOfOperation">
                  <Input.TextArea autoSize />
                </Form.Item>
                <Form.Item label="Schedule" name="schedule">
                  <Input.TextArea autoSize />
                </Form.Item>
                <Form.Item label="Play guidelines" name="playGuidelines">
                  <Input.TextArea autoSize />
                </Form.Item>
                <Form.Item
                  label="Tags (Press enter to add a new tag)"
                  name="tags"
                >
                  <Input
                    value={tagInput}
                    onChange={(e) => setTagInput(e.currentTarget.value)}
                    onPressEnter={(e) => {
                      addItemToTags(e.currentTarget.value)
                      setTagInput('')
                    }}
                  />
                  {tags?.map((tag) => (
                    <Tag
                      closable
                      onClose={() => removeItemFromTags(tag)}
                      style={{ marginTop: 10 }}
                    >
                      {tag}
                    </Tag>
                  ))}
                </Form.Item>
                <Form.Item label="No reservation text" name="noReservationText">
                  <Input />
                </Form.Item>
                <Form.Item
                  noStyle
                  label="Formatted address"
                  name="formattedAddress"
                  hidden
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input type="hidden" />
                </Form.Item>
                <Form.Item
                  noStyle
                  label="Longitude"
                  name="lng"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input type="hidden" />
                </Form.Item>
                <Form.Item
                  noStyle
                  label="Latitude"
                  name="lat"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input type="hidden" />
                </Form.Item>
                <Form.Item
                  noStyle
                  label="Place Id"
                  name="placeId"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input type="hidden" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Card
          bodyStyle={{
            height: 550,
            padding: 0,
          }}
        >
          <Map
            center={{
              lat: 40.73061,
              lng: -73.935242,
            }}
            getMapData={({ formattedAddress, lat, lng, placeId }) => {
              form.setFieldValue('formattedAddress', formattedAddress)
              form.setFieldValue('placeId', placeId)
              form.setFieldValue('lat', lat.toString())
              form.setFieldValue('lng', lng.toString())
            }}
            zoom={13}
          />
        </Card>
      </Form>
    </Create>
  )
}
