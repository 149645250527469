import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { Breadcrumb, Show, ShowButton, TextField } from '@refinedev/antd'
import {
  IResourceComponentsProps,
  useCreate,
  useDelete,
  useList,
  useOne,
  useShow,
} from '@refinedev/core'
import {
  Button,
  Col,
  List,
  Modal,
  Row,
  Select,
  Table,
  Tag,
  Typography,
} from 'antd'

import type { IGroup, IGroupRule, ILocation } from 'interfaces'
import { useState } from 'react'

export const LocationsShow: React.FC<IResourceComponentsProps> = () => {
  // 🪝 HOOKS
  const data = useShow<ILocation>()
  const [isConnectGroupRuleModal, setIsConnectGroupRuleModal] = useState(false)
  const [selectedGroupId, setSelectedGroupId] = useState(undefined)
  const [selectedRuleId, setSelectedRuleId] = useState(undefined)
  const { mutate: mutateDelete } = useDelete()
  const { mutate: mutateCreate } = useCreate()

  // 💿 DATA
  const record = data?.queryResult?.data?.data

  // fetch all groups
  const { data: allGroups } = useList<IGroup>({ resource: 'groups' })

  // when a group is selected, group details are fetched
  const { data: selectedGroup } = useOne<IGroup>({
    resource: `groups`,
    id: selectedGroupId,
    queryOptions: {
      enabled: !!selectedGroupId,
    },
  })

  // 🚀 Functions

  const showConnectGroupRuleModal = () => {
    setIsConnectGroupRuleModal(true)
  }

  const handleCancelConnectGroupRuleModal = () => {
    setIsConnectGroupRuleModal(false)
    setSelectedGroupId(undefined)
  }

  const deleteGroupRuleLocation = async (groupId: string, ruleId: string) => {
    const locationId = record?.id

    mutateDelete({
      resource: `groups/${groupId}/rules/${ruleId}/locations/${locationId}`,
      id: '',
      invalidates: ['all'],
    })
  }

  const connectGroupRuleLocation = async () => {
    const locationId = record?.id

    mutateCreate({
      resource: `groups/${selectedGroupId}/rules/${selectedRuleId}/locations`,
      values: {
        locationId,
      },
      invalidates: ['all'],
    })
  }

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col lg={24} xl={24} xs={24}>
          {' '}
          <Show breadcrumb={<Breadcrumb />} resource="locations">
            <Typography.Title level={3}>{record?.name}</Typography.Title>
            <img
              src={
                record?.images?.detail ??
                'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mN89x8AAuEB74Y0o2cAAAAASUVORK5CYII='
              }
              width={200}
              style={{
                marginBottom: '20px',
              }}
            ></img>
            <Typography.Title level={5}>Address:</Typography.Title>
            <TextField value={record?.formattedAddress} />
            <Typography.Title level={5}>Description:</Typography.Title>
            <TextField value={record?.description} />
            <Typography.Title level={5}>Timezone:</Typography.Title>
            <TextField value={record?.timezone} />
            <Typography.Title level={5}>Getting there info:</Typography.Title>
            <TextField value={record?.gettingThereInfo} />
            <Typography.Title level={5}>
              Default reservation window:
            </Typography.Title>
            <TextField value={`${record?.defaultReservationWindow} days`} />
            <Typography.Title level={5}>
              Reservation release time:
            </Typography.Title>
            <TextField value={record?.reservationReleaseTimeLocal} />
            <Typography.Title level={5}>Hours of operation:</Typography.Title>
            <TextField value={record?.hoursOfOperation} />
            <Typography.Title level={5}>Schedule:</Typography.Title>
            <TextField value={record?.schedule} />
            <Typography.Title level={5}>Play guidelines:</Typography.Title>
            <TextField value={record?.playGuidelines} />
            <Typography.Title level={5}>Tags:</Typography.Title>
            <TextField
              value={record?.tags.map((tag) => (
                <Tag>{tag}</Tag>
              ))}
            />
            <Typography.Title level={5}>Google place Id:</Typography.Title>
            <TextField value={record?.placeId} />
            <Typography.Title level={5}>Latitude:</Typography.Title>
            <TextField value={record?.lat} />
            <Typography.Title level={5}>Longitude:</Typography.Title>
            <TextField value={record?.lng} />
            <Typography.Title level={5}>Created at:</Typography.Title>
            <TextField value={record?.createdAt} />
            <Typography.Title level={5}>Updated at:</Typography.Title>
            <TextField value={record?.updatedAt} />
          </Show>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col lg={24} xl={24} xs={24}>
          <Typography.Title
            style={{
              marginTop: '20px',
            }}
            level={4}
          >
            Group-Rules for this location
          </Typography.Title>
          <Button
            type="primary"
            style={{
              marginRight: '20px',
              marginBottom: '20px',
              marginTop: '10px',
            }}
            onClick={showConnectGroupRuleModal}
          >
            <PlusOutlined />
            Connect Group-Rule to this group{' '}
          </Button>
          <List>
            <Table
              dataSource={record?.groupRules.map((groupRule) => ({
                key: groupRule.id,
                groupName: groupRule.group.name,
                groupId: groupRule.group.id,
                ruleName: groupRule.rule.name,
                ruleId: groupRule.rule.id,
                newPrice: groupRule.rule.rules.price,
                newReservationWindow: groupRule.rule.rules.reservationWindow,
                reservationType: groupRule.rule.reservationType,
              }))}
              rowKey="key"
            >
              <Table.Column title="Group" dataIndex="groupName" />
              <Table.Column
                dataIndex="id"
                key="show"
                render={(_, record: IGroupRule) => {
                  return (
                    <ShowButton
                      hideText
                      id={record.groupId}
                      recordItemId={record.groupId}
                      resource="groups"
                      size="small"
                    />
                  )
                }}
                title="Group detail"
                align="center"
              />
              <Table.Column title="Rule" dataIndex="ruleName" />
              <Table.Column
                title="Reservation type"
                dataIndex="reservationType"
              />
              <Table.Column title="Price override" dataIndex="newPrice" />
              <Table.Column
                title="Res. Win. override"
                dataIndex="newReservationWindow"
              />
              <Table.Column
                dataIndex="id"
                key="show"
                render={(_, record: IGroupRule) => {
                  return (
                    <ShowButton
                      hideText
                      id={record.ruleId}
                      recordItemId={record.ruleId}
                      resource="rules"
                      size="small"
                    />
                  )
                }}
                title="Rule detail"
                align="center"
              />
              <Table.Column
                dataIndex="id"
                key="disconnect"
                render={(_, record: IGroupRule) => {
                  return (
                    <Button
                      danger
                      onClick={() =>
                        deleteGroupRuleLocation(record.groupId, record.ruleId)
                      }
                      size="small"
                    >
                      <DeleteOutlined />
                    </Button>
                  )
                }}
                title="Disconnect"
                align="center"
              />
            </Table>
          </List>
        </Col>
      </Row>

      <Modal
        width={800}
        title="Connect Group-Rule to this location"
        open={isConnectGroupRuleModal}
        onCancel={handleCancelConnectGroupRuleModal}
        onOk={connectGroupRuleLocation}
      >
        <Typography.Paragraph>1. Select a group</Typography.Paragraph>
        <Select
          placeholder="Select a group"
          title="Select a group"
          style={{ width: 400 }}
          onSelect={(value) => setSelectedGroupId(value)}
          allowClear
        >
          {allGroups?.data.map((group) => (
            <Select.Option key={group.id} value={group.id}>
              {group.name}
            </Select.Option>
          ))}
        </Select>
        {selectedGroupId && (
          <>
            <Typography.Paragraph>2. Select a rule</Typography.Paragraph>
            <Select
              placeholder="Select a rule"
              title="Select a rule"
              style={{ width: 400 }}
              onSelect={(value) => setSelectedRuleId(value)}
              allowClear
            >
              {selectedGroup?.data.rules.map((rule) => (
                <Select.Option key={rule.id} value={rule.id}>
                  {rule.name}
                </Select.Option>
              ))}
            </Select>
          </>
        )}
      </Modal>
    </>
  )
}
