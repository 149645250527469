import { Show } from '@refinedev/antd'
import { useRouterContext, useShow, useList } from '@refinedev/core'
import { Card, List, Tag, Typography } from 'antd'
import React from 'react'
import { 
  IOrganization, 
  IOrganizationLocation, 
  IActivityOrCategoryOrderItem,
  IActivity,
  ICategory
} from '../../interfaces'

export const OrganizationsShow: React.FC = () => {
  const { queryResult } = useShow<IOrganization>({ resource: 'organizations' })
  const { Link } = useRouterContext()
  const record = queryResult?.data?.data

  // Get activities data for this organization
  const { data: activitiesData } = useList<IActivity>({
    resource: 'activities',
    filters: record?.id ? [
      {
        field: 'organizationId',
        operator: 'eq',
        value: record.id,
      },
    ] : [],
  })

  // Get categories data for this organization
  const { data: categoriesData } = useList<ICategory>({
    resource: 'categories',
    filters: record?.id ? [
      {
        field: 'organizationId',
        operator: 'eq',
        value: record.id,
      },
    ] : [],
  })

  // Get the name of an item based on its ID and type
  const getItemName = (id: string, type: 'ACTIVITY' | 'CATEGORY') => {
    if (type === 'ACTIVITY') {
      const activity = (activitiesData?.data || []).find(a => a.id === id)
      return activity?.name || id
    } else {
      const category = (categoriesData?.data || []).find(c => c.id === id)
      return category?.name || id
    }
  }

  return (
    <Show resource="organizations">
      {record?.logo && (
        <img
          src={record?.logo}
          width={200}
          style={{
            marginBottom: '20px',
          }}
        ></img>
      )}
      <Typography.Title level={3}>{record?.name}</Typography.Title>
      <Typography.Paragraph>
        {record?.locations.map((location: IOrganizationLocation) => (
          <Tag color="blue" key={location.id}>
            {location.name}
          </Tag>
        ))}
      </Typography.Paragraph>
      <Typography.Paragraph>Name: {record?.name}</Typography.Paragraph>
      <Typography.Paragraph>
        Description: {record?.description}
      </Typography.Paragraph>
      <Typography.Paragraph>Slug: {record?.slug}</Typography.Paragraph>
      <Typography.Paragraph>
        Section Waitlist Type: {record?.sectionWaitlistType ?? 'default'}
      </Typography.Paragraph>
      <Typography.Paragraph>
        Primary Timezone: {record?.config.general?.primaryTimezone ?? ''}
      </Typography.Paragraph>

      {record?.activityOrCategoryOrder &&
        record.activityOrCategoryOrder.length > 0 && (
          <div style={{ marginBottom: '20px' }}>
            <Card title="Activities & Categories Order" bordered={false}>
              <List
                dataSource={record.activityOrCategoryOrder}
                renderItem={(item: IActivityOrCategoryOrderItem, index) => (
                  <List.Item>
                    <Typography.Text>{index + 1}. </Typography.Text>
                    <Typography.Text style={{ marginLeft: 8 }}>
                      {getItemName(item.id, item.type)}
                    </Typography.Text>
                    <Tag
                      style={{ marginLeft: 8 }}
                      color={item.type === 'ACTIVITY' ? 'blue' : 'green'}
                    >
                      {item.type}
                    </Tag>
                  </List.Item>
                )}
              />
            </Card>
          </div>
        )}

      {record?.config.banners?.pages && (
        <div>
          <h2>Page Banners</h2>
          {Object.entries(record?.config.banners.pages).map(
            ([route, banner]) => (
              <div key={route} style={{ display: 'flex' }}>
                <Typography.Paragraph style={{ width: '50%' }}>
                  Page: {route}
                </Typography.Paragraph>
                <Typography.Paragraph>Banner: {banner}</Typography.Paragraph>
              </div>
            )
          )}
        </div>
      )}
    </Show>
  )
}
