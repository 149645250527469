import { Breadcrumb, Create, useForm } from '@refinedev/antd'
import type { IResourceComponentsProps } from '@refinedev/core'
import { useInvalidate, useList } from '@refinedev/core'
import { Form, Input, Select } from 'antd'
import type { IGroup, IOrganization } from 'interfaces'

export const GroupsCreate: React.FC<IResourceComponentsProps> = () => {
  //🪝 HOOKS
  const invalidate = useInvalidate()
  const { formProps, saveButtonProps } = useForm<IGroup>()

  // fetch organizations to select from
  const { data: organizations } = useList<IOrganization>({
    resource: 'organizations',
  })

  return (
    <Create
      breadcrumb={<Breadcrumb />}
      resource="groups"
      saveButtonProps={saveButtonProps}
    >
      <Form
        {...formProps}
        initialValues={{
          isActive: true,
        }}
        layout="vertical"
        style={{ marginTop: 30 }}
      >
        <Form.Item
          label="Name"
          name="name"
          required
          rules={[{ message: 'Please enter a group name' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[{ message: 'Please enter a group description' }]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          label="Group Type"
          name="groupType"
          required
          rules={[{ message: 'Please enter group type' }]}
        >
          <Select>
            <Select.Option value="residency">residency</Select.Option>
            <Select.Option value="special-group">special-group</Select.Option>
            <Select.Option value="for-purchase">for-purchase</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Eligibility"
          name="eligibility"
          rules={[{ message: 'Please enter a group eligibility' }]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          label="Join Instructions"
          name="joinInstructions"
          rules={[{ message: 'Please enter a group join instructions' }]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          label="Benefits"
          name="benefit"
          rules={[{ message: "Please enter a group's benefits" }]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item label="Organization" name="organizationId">
          <Select allowClear>
            {organizations?.data
              .sort((a, b) => {
                return a.name.localeCompare(b.name)
              })
              .map((organization: IOrganization) => (
                <Select.Option key={organization.id} value={organization.id}>
                  {organization.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      </Form>
    </Create>
  )
}
