import React, { useState, useEffect, useMemo } from 'react'
import {
  Form,
  Card,
  Button,
  Space,
  Select,
  Typography,
  Divider,
  Tag,
  Empty,
} from 'antd'
import { MenuOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { useList } from '@refinedev/core'
import { FormInstance } from 'antd/lib/form'
import { IActivityOrCategoryOrderItem, IActivity, ICategory } from 'interfaces'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

const { Title, Text } = Typography
const { Option } = Select

// Sortable item component
interface SortableItemProps {
  id: string
  type: 'ACTIVITY' | 'CATEGORY'
  index: number
  name: string
  onRemove: () => void
}

const SortableItem = ({
  id,
  type,
  index,
  name,
  onRemove,
}: SortableItemProps) => {
  // Create a consistent, unique ID for the sortable item
  const itemId = `${type}-${id}`

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: itemId })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    padding: '8px 16px',
    marginBottom: 8,
    background: '#f9f9f9',
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '1px solid #f0f0f0',
  }

  // Handle delete button click and prevent propagation to parent
  const handleDelete = (e: React.MouseEvent) => {
    // Stop the event from propagating to parent elements
    e.stopPropagation()
    e.preventDefault()

    // Call the onRemove function directly
    onRemove()
  }

  return (
    <div ref={setNodeRef} style={style}>
      <div
        style={{ display: 'flex', alignItems: 'center', cursor: 'grab' }}
        {...attributes}
        {...listeners}
      >
        <div style={{ marginRight: 8 }}>
          <MenuOutlined style={{ color: '#999' }} />
        </div>
        <Text>{name}</Text>
        <Tag
          style={{ marginLeft: 8 }}
          color={type === 'ACTIVITY' ? 'blue' : 'green'}
        >
          {type}
        </Tag>
      </div>
      <div onClick={handleDelete} style={{ cursor: 'pointer' }}>
        <Button type="text" danger icon={<DeleteOutlined />} />
      </div>
    </div>
  )
}

interface ActivityCategoryOrderProps {
  form: FormInstance
  organizationId: string
  initialValue?: IActivityOrCategoryOrderItem[]
}

/**
 * Component for managing the order of activities and categories
 */
export const ActivityCategoryOrder: React.FC<ActivityCategoryOrderProps> = ({
  form,
  organizationId,
  initialValue = [],
}) => {
  // Always initialize with an empty array, and then update with useEffect
  // This ensures we don't have stale state when initialValue changes
  const [orderItems, setOrderItems] = useState<IActivityOrCategoryOrderItem[]>(
    []
  )
  const [itemType, setItemType] = useState<'ACTIVITY' | 'CATEGORY'>('ACTIVITY')
  const [selectedItemId, setSelectedItemId] = useState<string>('')

  // Set up sensors for drag and drop
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  // Fetch activities for this organization
  const { data: activitiesData, isLoading: isActivitiesLoading } =
    useList<IActivity>({
      resource: 'activities',
      filters: [
        {
          field: 'organizationId',
          operator: 'eq',
          value: organizationId,
        },
      ],
    })

  // Fetch categories for this organization
  const { data: categoriesData, isLoading: isCategoriesLoading } =
    useList<ICategory>({
      resource: 'categories',
      filters: [
        {
          field: 'organizationId',
          operator: 'eq',
          value: organizationId,
        },
      ],
    })

  // Update form value when orderItems changes
  useEffect(() => {
    form.setFieldsValue({ activityOrCategoryOrder: orderItems })
  }, [orderItems, form])

  // Only initialize orderItems from initialValue once when component mounts
  const hasInitializedRef = React.useRef(false)

  useEffect(() => {
    // Only set from initialValue if we haven't initialized yet
    if (!hasInitializedRef.current) {
      if (Array.isArray(initialValue)) {
        setOrderItems(initialValue)
      }
      // Mark as initialized so we don't reset on subsequent renders
      hasInitializedRef.current = true
    }
  }, [initialValue])

  // Memoize available items to prevent unnecessary re-renders during drag operations
  const availableItems = useMemo(() => {
    const existingIds = orderItems.map((item) => item.id)

    if (itemType === 'ACTIVITY') {
      return (activitiesData?.data || []).filter(
        (activity) => !existingIds.includes(activity.id)
      )
    } else {
      return (categoriesData?.data || []).filter(
        (category) => !existingIds.includes(category.id)
      )
    }
  }, [itemType, activitiesData, categoriesData, orderItems])

  // Add an item to the order list
  const handleAddItem = () => {
    if (!selectedItemId) return

    setOrderItems([...orderItems, { id: selectedItemId, type: itemType }])
    setSelectedItemId('')
  }

  // Remove an item from the order list
  const handleRemoveItem = (index: number) => {
    const newItems = [...orderItems]
    newItems.splice(index, 1)
    setOrderItems(newItems)
  }

  // Handle drag end event with dnd-kit
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event

    if (over && active.id !== over.id) {
      setOrderItems((items) => {
        // Find the indices in our items array
        const oldIndex = items.findIndex(
          (item) => `${item.type}-${item.id}` === active.id
        )
        const newIndex = items.findIndex(
          (item) => `${item.type}-${item.id}` === over.id
        )

        if (oldIndex !== -1 && newIndex !== -1) {
          return arrayMove(items, oldIndex, newIndex)
        }

        return items
      })
    }
  }

  // Get the name of an item based on its ID and type - memoize for performance
  const getItemName = useMemo(
    () => (id: string, type: 'ACTIVITY' | 'CATEGORY') => {
      if (!id) return 'Unknown'

      if (type === 'ACTIVITY') {
        // If activities data is not loaded yet, return a placeholder
        if (!activitiesData?.data) {
          return `Activity (loading...)`
        }
        const activity = activitiesData.data.find((a) => a.id === id)
        return activity?.name || `Activity ${id.substring(0, 6)}...`
      } else {
        // If categories data is not loaded yet, return a placeholder
        if (!categoriesData?.data) {
          return `Category (loading...)`
        }
        const category = categoriesData.data.find((c) => c.id === id)
        return category?.name || `Category ${id.substring(0, 6)}...`
      }
    },
    [activitiesData, categoriesData]
  )

  return (
    <Card title="Pinned Activities & Categories">
      <Space direction="vertical" style={{ width: '100%' }}>
        <Text type="secondary">
          Drag items to reorder. The items shown here will be used as suggested
          filter options for the organization's programming page.
        </Text>

        <Form.Item name="activityOrCategoryOrder" hidden>
          <input />
        </Form.Item>

        {orderItems.length === 0 ? (
          <Empty description="No items added yet." />
        ) : (
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={orderItems.map((item) => `${item.type}-${item.id}`)}
              strategy={verticalListSortingStrategy}
            >
              <div style={{ minHeight: '50px' }}>
                {orderItems.map((item, index) => {
                  // Create a unique ID for each item
                  const uniqueId = `${item.type}-${item.id}`

                  // Add a callback specific to this item
                  const removeThisItem = () => {
                    handleRemoveItem(index)
                  }

                  return (
                    <SortableItem
                      key={uniqueId}
                      id={item.id}
                      type={item.type}
                      index={index}
                      name={getItemName(item.id, item.type)}
                      onRemove={removeThisItem}
                    />
                  )
                })}
              </div>
            </SortableContext>
          </DndContext>
        )}

        <Divider />

        <Title level={5}>Add Items</Title>
        <Space style={{ marginBottom: 16 }}>
          <Select
            value={itemType}
            onChange={(value: 'ACTIVITY' | 'CATEGORY') => setItemType(value)}
            style={{ width: 120 }}
          >
            <Option value="ACTIVITY">Activity</Option>
            <Option value="CATEGORY">Category</Option>
          </Select>

          <Select
            placeholder={`Select a ${itemType.toLowerCase()}`}
            style={{ width: 300 }}
            value={selectedItemId}
            onChange={setSelectedItemId}
            showSearch
            filterOption={(input, option) =>
              (option?.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            loading={
              itemType === 'ACTIVITY'
                ? isActivitiesLoading
                : isCategoriesLoading
            }
          >
            {availableItems.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>

          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleAddItem}
            disabled={!selectedItemId}
          >
            Add
          </Button>
        </Space>
      </Space>
    </Card>
  )
}

export default ActivityCategoryOrder
